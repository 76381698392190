import React, { ReactNode, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';

import { ConnectedNavBar, ConnectedNavBarDrawer } from '../../navbar';
import { AppFrameNavbar } from '../../../components/frame';
import { SectionRouting } from '../section';
import { setNavbarOpenAction } from '../../../data/actions/foundation';
import { IContainerConfig } from '../../../model/configuration/ContainerConfig';
import { DetailLoaderComponent } from '../../detail-loader';
import { UserByIdRedirect } from '../../userbyidredirect';
import { NotificationDashboardComponent } from '../../../components/notifications';
import { ConnectedFlowComponentFrame } from '../../flow';
import { useBreakpoints } from '@curry-group/mui-curcuma';
import { isGatedContentRoute, isGatedContentType, TYPES_PROPS } from '../../../helper';
import { useIsMobile } from '../../../helper/hooks/isMobile';
import { NewsDashboardComponent } from '../../../components/news';
import { ConnectedOptoutFrame } from '../../auth/optout';

export const FrameRouting = () => {
  const location = useLocation();
  const isLargeDesktop = !!useBreakpoints().lgUp;
  const navigation = useSelector(state => state.foundation?.navigation);
  const appconfig = useSelector(state => state.foundation?.appconfig);
  const auth = useSelector(state => state.foundation.auth);
  const detailItem = useSelector(state => state.detail.item);
  const strategicDialog = useSelector(state => state.detail?.item?.content?.strategicDialog);
  const isMobile = useIsMobile();

  const userProfileContainer: IContainerConfig = {
    name: 'user',
    alias: 'user',
    groupAlias: 'user',
    navigation: true,
    container: true,
    cooperation: false,

    subContainer: [],
    contentType: [],

    inheritSettings: false,
    displayWindow: false,
    pageElements: [],
    footer: true,
    dashboardMemoryLists: false,
    botSearchInitial: false,

    types: {}
  };

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setNavbarOpenAction(false));
  }, [location.pathname, dispatch]);

  if (!navigation?.length) {
    return <></>;
  }

  const routes: ReactNode[] = [];
  routes.push(
    <Route exact path={['/']} key={'redirect'}>
      <Redirect to={'/' + navigation?.[0]?.configs?.[0]?.alias} />
    </Route>
  );

  routes.push(
    <Route exact path={'/start'} key={'notifications'}>
      <NotificationDashboardComponent />
    </Route>
  );

  routes.push(
    <Route exact path={'/news'} key={'news'}>
      <NewsDashboardComponent />
    </Route>
  );

  routes.push(
    <Route exact path={'/news/search'} key={'news'}>
      <NewsDashboardComponent />
    </Route>
  );

  routes.push(
    <Route path={'/news/flow'} key={'newsflow'}>
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>
      <ConnectedFlowComponentFrame alias={'share'} />
    </Route>
  )

  routes.push(
    <Route path={'/memorylist/flow'} key={'memorylistflow'}>
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>
      <ConnectedFlowComponentFrame alias={'share'} />
    </Route>
  )

  routes.push(
    <Route path={'/memorylist/search/flow'} key={'memorylistsearchflow'}>
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>
      <ConnectedFlowComponentFrame alias={'share'} />
    </Route>
  )

  routes.push(
    <Route path={'/userbyid/:userId'} key={'otherUserProfile'}>
      <UserByIdRedirect />
    </Route>
  );

  routes.push(
    <Route exact path={'/user/:alias'} key={'otherUserProfileAlias'}>
      <DetailLoaderComponent config={userProfileContainer} />
    </Route>
  );

  routes.push(
    <Route exact path={'/user/:alias/flow'} key={'otherUserProfileFlow'}>
      <ConnectedFlowComponentFrame alias={'share'} />
    </Route>
  );
  
  routes.push(
    <Route exact path={['/optout']} key={'notAnonOptout'}>
      <ConnectedOptoutFrame noSidebar={true} />
    </Route>
  );

  navigation?.forEach((nav, index) => {
    const route = (
      <Route path={['/' + nav.alias]} key={`${nav.alias}_${index}`}>
        <SectionRouting sections={nav.configs} configs={appconfig || {}} />
      </Route>
    );
    if (!nav.alias) {
      routes.push(route);
    } else {
      routes.unshift(route);
    }
  });
  const bool_isGatedContentType = isGatedContentType(detailItem?.type);
  let type = detailItem?.type || '';
  if (!TYPES_PROPS[type]) {
    type = '';
  }
  const ogImage = (type && strategicDialog) ? TYPES_PROPS[type].OgImage2 : TYPES_PROPS[type].OgImage;
  let description = '';
  if (detailItem && bool_isGatedContentType && isGatedContentRoute(location.pathname)) {
    description = detailItem.content?.content?.replace(/<[^>]*>/gim, ' ').replace(/\s{2,}/gim, ' ').trim().substring(0,160) + '...';
  }

  return (
    <React.Fragment>
      {detailItem && bool_isGatedContentType && isGatedContentRoute(location.pathname) && (
        <Helmet>
          <title>{detailItem.content.title + ' - Medtec Online'}</title>
          <meta name="description" content={description}></meta>
          <meta property="og:url" content={window.location.protocol + '//' + window.location.host + location.pathname}></meta>
          <meta property="og:type" content="website"></meta>
          <meta property="og:title" content={detailItem.content.title}></meta>
          <meta property="og:description" content={description}></meta>
          <meta property="og:image" content={window.location.protocol + '//' + window.location.host + ogImage?.src}></meta>
        </Helmet>
      )}
      {auth && (
        <>
          <ConnectedNavBarDrawer />
          {!isMobile && (
            <AppFrameNavbar>
              <ConnectedNavBar small={!isLargeDesktop} showHamburger={!isLargeDesktop} />
            </AppFrameNavbar>
          )}
        </>
      )}
      <Switch>{routes}</Switch>
    </React.Fragment>
  );
};
