import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { fetchFirstPageSearchEntriesRequestAction, setSearchWorkingAction } from '../../data/actions/search';
import { Btn, Bx, IconBtn, Typo, useBreakpoints } from '@curry-group/mui-curcuma';
import clsx from 'clsx';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Markdown from 'react-markdown';
import remarkGfm from 'remark-gfm'
import { Skeleton } from '@material-ui/lab';
import { faLightbulbOn, faMessageBot, faRedo } from '@fortawesome/pro-light-svg-icons';
import useTheme from '@material-ui/core/styles/useTheme';
import { debounce as _debounce } from 'lodash';
import { botResponseEndAction, trackBotFeedbackAction } from '../../data/actions/bot';
import { setFlowAnonymousAction, setFlowDataAction, setFlowTypeAction } from '../../data/actions/flow';
import { NewQnAEntry } from '../widget/forum-popup/new-qna-entry';

export interface IBotSearchResultProps {
  working?: boolean;
  isMobile?: boolean;
}

const useStyles = makeStyles(theme => ({
  markdown: {
    '& > *:first-child': {
      marginTop: 0
    },
    '& pre': {
      whiteSpace: 'pre-wrap',
      wordWrap: 'break-word'
    }
  }
}));

export const BotSearchResults: React.FC<IBotSearchResultProps> = ({
  working,
  isMobile = false
}) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const classes = useStyles();
  const botAnswer = useSelector(state => state.bot?.botSearch?.botAnswer);
  const botRequestId = useSelector(state => state.bot?.botSearch?.botRequestId);
  const botFeedback = useSelector(state => state.bot?.botSearch?.botFeedback);
  const positiveFeedback = useSelector(state => state.filter?.filterItem?.content?.positiveFeedback);
  const negativeFeedback = useSelector(state => state.filter?.filterItem?.content?.negativeFeedback);
  const queryTerm = useSelector(state => state.filter?.searchObject?.queryTerm);
  const answerCategories = useSelector(state => state.search?.result?.answer_categories);
  const [newQnAEntryOpen, setNewQnAEntryOpen] = useState<boolean>(false);

  let feedbackItems: Array<{name:string; pos:boolean;}> = [];
  if (!!positiveFeedback && positiveFeedback.length > 0) {
    feedbackItems = [...feedbackItems, ...positiveFeedback.map((item: { name: string; }) => ({name: item.name, pos:true}))];
  }
  if (!!negativeFeedback && negativeFeedback.length > 0) {
    feedbackItems = [...feedbackItems, ...negativeFeedback.map((item: { name: string; }) => ({name: item.name, pos:false}))];
  }
  
  const dispatcher = {
    debouncedFetchFirstPageSearchEntries: _debounce(() => {
      dispatch(fetchFirstPageSearchEntriesRequestAction?.({}));
    }, 350),
    setSearchWorking: (working: boolean) => dispatch(setSearchWorkingAction({ working })),
    trackBotFeedback: (botRequestId: string, feedback: string, rating: number, queryTerm?: string) => {
      dispatch(trackBotFeedbackAction?.({ botRequestId, feedback, rating, queryTerm }));
    },
    setBotResponseAction: (botAnswer: string, botRequestId: string) => dispatch(botResponseEndAction?.({response: botAnswer, request_id: botRequestId})),
    setQnaFlowQuestionAction: (query: string, botAnswer: string, answerCategories: Array<string>) => dispatch(setFlowDataAction({
      alias: 'forum',
      flowData: {
        noFlow: true,
        itemData: {
          title: query,
          botAnswer: botAnswer,
          answerCategories: answerCategories
        }
      }
    })),
    setQnaFlowInitAction: () => {
      dispatch(setFlowTypeAction({type: 'qna'}));
      dispatch(setFlowAnonymousAction({anonymous: false}));
    }
  };

  return (
    <>
      <NewQnAEntry newQnAEntryOpen={newQnAEntryOpen} close={() => setNewQnAEntryOpen(false)} cancel={() => setNewQnAEntryOpen(false)}/>
      <Bx display="flex"
        flexDirection={isMobile ? 'column' : 'row'}
        style={{
          border: '1px solid transparent',
          borderRadius: 16,
          background:'rgb(230, 245, 251)',
          padding:'16px'
        }}
        mb={!botRequestId ? 2 : 0}
      >
        <Bx pr={2}>
          <Typo variant="h1" component="p">
            <FontAwesomeIcon icon={faMessageBot} />
          </Typo>
        </Bx>
        <Bx>
          {(!working && !botAnswer) && <Typo variant="body1" component="p" style={{width:'100%',marginTop:'8px'}}>
            Geben Sie oben eine fachliche Frage ein und schicken Sie Ihre Anfrage per Eingabe-Taste (ENTER) oder den blauen Button ab.
            </Typo>}
          {(working && !botAnswer) && <Typo variant="body1" component="p" style={{width:'100%',marginTop:'8px'}}>
            <Skeleton animation="pulse" width="16px" style={{display:'inline-block'}} />
            &nbsp;
            <Skeleton animation="pulse" width="16px" style={{display:'inline-block'}} />
            &nbsp;
            <Skeleton animation="pulse" width="16px" style={{display:'inline-block'}} />
          </Typo>}
          {!!botAnswer && <Markdown className={clsx(classes.markdown)} remarkPlugins={[remarkGfm]} linkTarget="_blank">{(botAnswer ?? '')}</Markdown>}
        </Bx>
      </Bx>
      {/* {true && */}
      {botRequestId &&
        <Bx my={2} display={'flex'}
          style={{
            flexDirection: 'row',
            flexWrap: 'wrap'
          }}
        >
        <Bx>
          <IconBtn
            onClick={() => {
              dispatcher.setSearchWorking?.(true);
              dispatcher.setBotResponseAction?.('', '');
              dispatcher.debouncedFetchFirstPageSearchEntries?.();
            }}
            size="small"
          >
            <FontAwesomeIcon icon={faRedo} />
          </IconBtn>
        </Bx>
        {/* {botAnswer && !!feedbackItems && feedbackItems.length > 0 && feedbackItems.map((item, index) => { */}
        {!!feedbackItems && feedbackItems.length > 0 && feedbackItems.map((item, index) => {
          const itemColor = item.pos
            ? theme.palette.secondary.main
            : theme.palette.error.main;
          return (
            <Bx
            key={`BOT_FEEDBACK_ITEM_${index}`}
              display="flex"
              justifyContent="center"
              alignItems="center"
              minWidth={36}
              px={1.5}
              py={.5}
              ml={1}
              mb={1}
              style={{
                cursor:'pointer',
                borderRadius:8,
                border:`1px solid ${botFeedback === item.name ? 'transparent' : itemColor}`,
                background: botFeedback === item.name ? itemColor : 'transparent',
                color: botFeedback === item.name ? 'white' : itemColor
              }}
              onClick={() => {
                dispatcher.trackBotFeedback?.(botRequestId || '', item.name, 1, queryTerm);
              }}
            >
              <Typo
                style={{
                  wordBreak:'keep-all'
                }}
                variant="body1"
              >{item.name}</Typo>
            </Bx>
        )})}
        <Bx ml={'auto'}>
          <Btn
            size="large"
            startIcon={<FontAwesomeIcon icon={faLightbulbOn} />}
            onClick={() => {
              dispatcher.setQnaFlowQuestionAction?.(
                queryTerm || '',
                botAnswer || '',
                answerCategories || []
              );
              dispatcher.setQnaFlowInitAction?.();
              setNewQnAEntryOpen(true);
            }}
            color={'secondary'}
            variant={'contained'}
            title='Hier können Sie Ihre Frage an das Forum stellen.'
          >
            {'Ans Forum wenden'}
          </Btn>
        </Bx>
      </Bx>
    }
    </>
  );
};

export const ConnectedBotSearchResults = () => {
  const isMobile = !!useBreakpoints().mdDown;
  const working = useSelector(state => state.search?.working);

  return (
    <BotSearchResults
      working={working}
      isMobile={isMobile}
    />
  );
};
