import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { cloneDeep } from 'lodash';
import { useHistory } from 'react-router-dom';
import Hidden from '@material-ui/core/Hidden';
import { AppFrameContent, AppFrameHeader } from '../frame';
import { resolveTimestampDateOnly } from '../../helper';
import { transformForList } from '../../model/ryve/Thing';
import { ListWidget } from '../widget/list';
import { FramedContent } from '../framed-content';
import { Btn, Bx, IconBtn, Typo, shorten, useBreakpoints } from '@curry-group/mui-curcuma';
import { fetchFirstPageNewsRequestAction, fetchNextPageNewsRequestAction, resetNewsAction } from '../../data/actions/news';
import { OpenExternalLinkDialog } from '../dialogs/open-external-link';
import { faMegaphone, faMessageBot, faRedo } from '@fortawesome/pro-light-svg-icons';
import { setFlowDataAction } from '../../data/actions/flow';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { OpenNewsInfoDialog } from '../dialogs/open-news-info';
import { faInfoCircle } from '@fortawesome/pro-regular-svg-icons';
import { useConfiguration } from '../../data/sagas/foundation';
import { setAppStateActiveConfigAction, userprofileMemorylistRequestAction } from '../../data/actions/foundation';
import { IMemoryListItem } from '../../model/ryve/Thing';
import { useFilterConfig, useFilterState } from '../../data/sagas/filter';
import clsx from 'clsx';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { ConnectedDashboardHeader } from '../header/dashboard';
import { Skeleton } from '@material-ui/lab';
import Markdown from 'react-markdown';
import remarkGfm from 'remark-gfm'
import { setSearchWorkingAction } from '../../data/actions/search';
import useTheme from '@material-ui/core/styles/useTheme';
import { botResponseEndAction, trackBotFeedbackAction } from '../../data/actions/bot';

const useStyles = makeStyles(theme => ({
  markdown: {
    '& > *:first-child': {
      marginTop: 0
    },
    '& pre': {
      whiteSpace: 'pre-wrap',
      wordWrap: 'break-word'
    }
  }
}));

export const NewsDashboardComponent = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const classes = useStyles();
  const config = useConfiguration('news');
  useFilterConfig('news', false, false, false, config?.botSearchInitial);
  const history = useHistory();
  const [newsInitial, setNewsInitial] = useState<boolean>(true);
  const [reTransform, setReTransform] = useState<string | undefined>('');
  const [news, setNews] = useState<any>([]);
  const [references, setReferences] = useState<any>([]);
  const [openExternalLinkDialog, setOpenExternalLinkDialog] = useState(false);
  const [openExternalLink, setOpenExternalLink] = useState('');
  const [openNewsInfoDialog, setOpenNewsInfoDialog] = useState(false);
  const { searchObject } = useFilterState();
  
  const isMobile = !!useBreakpoints().mdDown;

  const memoryList = useSelector(state => state.foundation?.profile?.memoryList);
  const memoryIds = memoryList?.map(m => m.externalId);
  
  const externalLinksAllowed = useSelector(state => state.foundation?.profile?.externalLinksAllowed);
  const newsItems = useSelector(state => state.news?.items);
  const referenceItems = useSelector(state => state.news?.references);
  const working = useSelector(state => state.news?.working);
  const moreDataAvailable = useSelector(state => state.news?.moreDataAvailable);
  const botSearchActive = useSelector(state => state.bot?.botSearchActive);
  const botAnswer = useSelector(state => state.bot?.botSearch?.botAnswer);
  const botRequestId = useSelector(state => state.bot?.botSearch?.botRequestId);
  const botFeedback = useSelector(state => state.bot?.botSearch?.botFeedback);
  const positiveFeedback = useSelector(state => state.filter?.filterItem?.content?.positiveFeedback);
  const negativeFeedback = useSelector(state => state.filter?.filterItem?.content?.negativeFeedback);

  let feedbackItems: Array<{name:string; pos:boolean;}> = [];
  if (!!positiveFeedback && positiveFeedback.length > 0) {
    feedbackItems = [...feedbackItems, ...positiveFeedback.map((item: { name: string; }) => ({name: item.name, pos:true}))];
  }
  if (!!negativeFeedback && negativeFeedback.length > 0) {
    feedbackItems = [...feedbackItems, ...negativeFeedback.map((item: { name: string; }) => ({name: item.name, pos:false}))];
  }

  const dispatcher = {
    fetchFirstPageNewsAction: () => dispatch(fetchFirstPageNewsRequestAction?.({})),
    fetchNextPageNewsAction: () => dispatch(fetchNextPageNewsRequestAction?.()),
    resetNewsAction: (loading: boolean) => dispatch(resetNewsAction?.({loading})),
    setSearchWorking: (working: boolean) => dispatch(setSearchWorkingAction({ working })),
    trackBotFeedback: (botRequestId: string, feedback: string, rating: number, queryTerm?: string) => {
      dispatch(trackBotFeedbackAction?.({ botRequestId, feedback, rating, queryTerm }));
    },
    setBotResponseAction: (botAnswer: string, botRequestId: string) => dispatch(botResponseEndAction?.({response: botAnswer, request_id: botRequestId}))
  };

  const routeChangeFeedback = () => {
    history.push('/coop/groups-projects/groups/medtec-online-newsticker-feedbackforum');
  };
  const routeChangeProfile = () => {
    history.push('/user/profile?ref=/news');
  };

  const handleScroll = () => {
    if (window.innerHeight + document.documentElement.scrollTop + window.innerHeight
      <= document.documentElement.offsetHeight
    ) {
      return;
    }
    if (!working && moreDataAvailable) {
      dispatcher.fetchNextPageNewsAction();
    }
  };

  useEffect(() => {
    if (!working && newsInitial) {
      dispatch(fetchFirstPageNewsRequestAction?.({}));
    }
  }, [dispatch, working, newsInitial]);

  useEffect(() => {
    if (newsInitial) {
      setNewsInitial(false);
    }
  }, [newsInitial]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  });

  useEffect(() => {
    config && dispatch(setAppStateActiveConfigAction(config));
  }, [config, dispatch]);

  useEffect(() => {
    if (newsItems) {
      const transformedItems: any[] = cloneDeep(newsItems)
        .map(news => {
          return {
            _id: news.content?.id,
            _score: news.content?.score,
            _source: {
              __original: news,
              type: news.type,
              content: news.content,
              created: dayjs(news.modifiedAt).unix() * 1000
            }
          };
        });
      const memoryClickedId = reTransform?.split('_|_|_')[0];
      setNews(
        transformForList(transformedItems).map(news => {
          const pl = news.__original?.content?.permissionLevel;
          let bodyText = '';
          switch(pl) {
            case 1:
              bodyText = news.__original?.content?.description ? shorten(news.__original?.content?.description, 500) : shorten(news.__original?.content?.body, 500);
              break;
            case 2:
              bodyText = news.__original?.content?.description ? shorten(news.__original?.content?.description, 500) : '';
              break;
            case 3:
            case 4:
            default:
              break;
          }
          return {
            ...news,
            type: resolveTimestampDateOnly(news.__original?.content?.pubDate),
            title: news.title,
            body: bodyText,
            to: news.__original?.content?.url,
            source: news.__original?.content?.sourcePreferredLabel,
            target: '_blank',
            categories: !!news.__original?.content?.categories ? news.__original?.content?.categories?.split(',') : undefined,
            itemTemplate: 'news',
            shareClick: () => {
              dispatch(
                setFlowDataAction({
                  alias: 'share',
                  flowData: {
                    location: history.location,
                    itemData: { sharedItem: news.__original }
                  }
                })
              );
              history.push(history.location.pathname + '/flow' + history.location.search);
            },
            booked: (!!memoryIds && !!news.__original?.content?.url
              ? memoryIds.includes(news.__original?.content?.url)
                ? !!memoryClickedId
                  ? news.__original?.content?.url === memoryClickedId ? false : true
                  : true
                : !!memoryClickedId
                  ? news.__original?.content?.url === memoryClickedId
                  : false
              : false
            ),
            selected: externalLinksAllowed
              ? undefined
              : () => {
                setOpenExternalLink(news.__original?.content?.url);
                setOpenExternalLinkDialog(true);
                return false;
              }
          }})
      );
    }
  }, [newsItems, externalLinksAllowed, reTransform]);

  useEffect(() => {
    if (referenceItems) {
      const transformedItems: any[] = cloneDeep(referenceItems)
        .map(news => {
          return {
            _id: news.content?.id,
            _score: news.content?.score,
            _source: {
              __original: news,
              type: news.type,
              content: news.content,
              created: dayjs(news.modifiedAt).unix() * 1000
            }
          };
        });
      const memoryClickedId = reTransform?.split('_|_|_')[0];
      setReferences(
        transformForList(transformedItems).map(news => {
          const pl = news.__original?.content?.permissionLevel;
          let bodyText = '';
          switch(pl) {
            case 1:
              bodyText = news.__original?.content?.description ? shorten(news.__original?.content?.description, 500) : shorten(news.__original?.content?.body, 500);
              break;
            case 2:
              bodyText = news.__original?.content?.description ? shorten(news.__original?.content?.description, 500) : '';
              break;
            case 3:
            case 4:
            default:
              break;
          }
          return {
            ...news,
            type: resolveTimestampDateOnly(news.__original?.content?.pubDate),
            title: news.title,
            body: bodyText,
            to: news.__original?.content?.url,
            source: news.__original?.content?.sourcePreferredLabel,
            target: '_blank',
            categories: !!news.__original?.content?.categories ? news.__original?.content?.categories?.split(',') : undefined,
            itemTemplate: 'news',
            shareClick: () => {
              dispatch(
                setFlowDataAction({
                  alias: 'share',
                  flowData: {
                    location: history.location,
                    itemData: { sharedItem: news.__original }
                  }
                })
              );
              history.push(history.location.pathname + '/flow' + history.location.search);
            },
            booked: (!!memoryIds && !!news.__original?.content?.url
              ? memoryIds.includes(news.__original?.content?.url)
                ? !!memoryClickedId
                  ? news.__original?.content?.url === memoryClickedId ? false : true
                  : true
                : !!memoryClickedId
                  ? news.__original?.content?.url === memoryClickedId
                  : false
              : false
            ),
            selected: externalLinksAllowed
              ? undefined
              : () => {
                setOpenExternalLink(news.__original?.content?.url);
                setOpenExternalLinkDialog(true);
                return false;
              }
          }})
      );
    }
  }, [referenceItems, externalLinksAllowed, reTransform]);

  return (
    <>
      <AppFrameHeader>
        <ConnectedDashboardHeader
          placeholder={'Newsticker durchsuchen'}
          alias={'news'}
        />
      </AppFrameHeader>
      <AppFrameContent>
        <FramedContent pt={botSearchActive ? 2 : undefined}>
          {botSearchActive &&
            <Bx display="flex"
              flexDirection={isMobile ? 'column' : 'row'}
              style={{
                border: '1px solid transparent',
                borderRadius:16,
                background:'rgb(230, 245, 251)',
                padding:'16px'
              }}
              mb={!botRequestId ? 2 : 0}
            >
            <Bx pr={2}>
              <Typo variant="h1" component="p">
                <FontAwesomeIcon icon={faMessageBot} />
              </Typo>
            </Bx>
            <Bx>
              {(!working && !botAnswer) && <Typo variant="body1" component="p" style={{width:'100%',marginTop:'8px'}}>
                Geben Sie oben eine fachliche Frage ein und schicken Sie Ihre Anfrage per Eingabe-Taste (ENTER) oder den blauen Button ab.
                </Typo>}
              {(working && !botAnswer) && <Typo variant="body1" component="p" style={{width:'100%',marginTop:'8px'}}>
                <Skeleton animation="pulse" width="16px" style={{display:'inline-block'}} />
                &nbsp;
                <Skeleton animation="pulse" width="16px" style={{display:'inline-block'}} />
                &nbsp;
                <Skeleton animation="pulse" width="16px" style={{display:'inline-block'}} />
              </Typo>}
              {!!botAnswer && <Markdown className={clsx(classes.markdown)} remarkPlugins={[remarkGfm]} linkTarget="_blank">{(botAnswer ?? '')}</Markdown>}
            </Bx>
          </Bx>}
          {botSearchActive && botAnswer && botRequestId &&
          <Bx my={2} display={'flex'}
            style={{
              flexDirection: 'row',
              flexWrap: 'wrap'
            }}
          >
            <Bx>
              <IconBtn
                onClick={() => {
                  dispatcher.setSearchWorking?.(true);
                  dispatcher.setBotResponseAction?.('', '');
                  dispatcher.fetchFirstPageNewsAction?.();
                }}
                size="small"
              >
                <FontAwesomeIcon icon={faRedo} />
              </IconBtn>
            </Bx>
            {botAnswer && !!feedbackItems && feedbackItems.length > 0 && feedbackItems.map((item, index) => {
              const itemColor = item.pos
                ? theme.palette.secondary.main
                : theme.palette.error.main;
              return (
                <Bx
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  minWidth={36}
                  px={1.5}
                  py={.5}
                  ml={1}
                  mb={1}
                  style={{
                    cursor:'pointer',
                    borderRadius:8,
                    border:`1px solid ${botFeedback === item.name ? 'transparent' : itemColor}`,
                    background: botFeedback === item.name ? itemColor : 'transparent',
                    color: botFeedback === item.name ? 'white' : itemColor
                  }}
                  onClick={() => {
                    dispatcher.trackBotFeedback?.(botRequestId, item.name, 1, searchObject?.queryTerm);
                  }}
                >
                  <Typo
                    style={{
                      wordBreak:'keep-all'
                    }}
                    variant="body1"
                  >{item.name}</Typo>
                </Bx>
            )})}
          </Bx>}
          {!!references && references.length > 0 && <ListWidget
            headline={
              <Bx display={'flex'}>
                <Typo variant="h3" component="h3">
                  Referenzen
                </Typo>
              </Bx>
            }
            noWorkingHeadline={true}
            endAdornment={undefined}
            items={references}
            working={working}
            divider={'none'}
            listType={'news'}
            itemMemoClicked={(item: IMemoryListItem, type: 'GET' | 'POST' | 'DELETE', seed?: string) => {
              dispatch(userprofileMemorylistRequestAction({ memoryItem: item, type }));
              setReTransform(item.externalId + '_|_|_' + seed);
            }}
          />}
          <ListWidget
            headline={
              <Bx display={'flex'}>
                <Typo variant="h3" component="h3">
                  {!!searchObject?.queryTerm && !botSearchActive
                    ? `Suchergebnisse (${news?.length || 0})`
                    : 'Aktuelle Meldungen zu Ihren Interessen'}
                </Typo>
                &nbsp;
                {!searchObject?.queryTerm &&
                <Typo variant="h3" component="span">
                  <FontAwesomeIcon
                    icon={faInfoCircle}
                    onClick={() => {setOpenNewsInfoDialog(true);}}
                    color="white"
                    style={{backgroundColor:'#004B76', borderRadius:'50%', cursor:'pointer', fontSize:'1.8rem'}}
                  />
                </Typo>}
              </Bx>
            }
            noWorkingHeadline={true}
            endAdornment={
              <Btn onClick={routeChangeFeedback} title="Feedback zum Newsticker" variant="text" size="large" endIcon={<FontAwesomeIcon icon={faMegaphone} />}>
                <Hidden smDown>{'Feedback zum Newsticker'}</Hidden>
              </Btn>
            }   
            items={news}
            working={working}
            divider={'none'}
            listType={'news'}
            itemMemoClicked={(item: IMemoryListItem, type: 'GET' | 'POST' | 'DELETE', seed?: string) => {
              dispatch(userprofileMemorylistRequestAction({ memoryItem: item, type }));
              setReTransform(item.externalId + '_|_|_' + seed);
            }}
          />
        </FramedContent>
        <OpenExternalLinkDialog
          open={openExternalLinkDialog}
          headerText={'Sie verlassen Medtec Online'}
          noteToUserText={'Bitte beachten Sie, dass dieser Link eine unabhängige Website öffnet, für deren Inhalt und Betrieb wir nicht verantwortlich sind; die Abfrage erfolgt direkt vom Server des Dritten. Für die Bereitstellung, Datenverarbeitung und den Inhalt ist der Dritte verantwortlich. Diese Meldung wird einmalig angezeigt, ihr Inhalt gilt auch für zukünftige Abrufe von Drittwebseiten.'}
          labelText={'Weiter zur externen Seite'}
          link={openExternalLink}
          onCanceled={() => {setOpenExternalLinkDialog(false);}}
        />
        <OpenNewsInfoDialog
          open={openNewsInfoDialog}
          headerText={'Interessen Ihres Profils'}
          noteToUserText={'Pflegen Sie die Interessen in Ihrem Profil, um personalisierte News angezeigt zu bekommen.'}
          maturityText={'Eine News bleibt 30 Tage im Newsticker angezeigt.'}
          labelText={'Zum Profil'}
          onClick={routeChangeProfile}
          onCanceled={() => {setOpenNewsInfoDialog(false);}}
        />
      </AppFrameContent>
    </>
  );
};
